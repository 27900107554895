<template>
  <div>
    <div class="all-container">
      <div class="map-box">
        <div class="map-warper">
          <l-map v-if="mapShow" class="aside-map" :zoom="zoom" :center="center" :crs="crs" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">
            <l-tile-layer :url="url" :subdomains="subdomains" :options="tileLayerOptions" :tms="tms"></l-tile-layer>
            <v-marker-cluster>
              <l-marker v-for="item in mapList" :key="item.id" :lat-lng="item.latlng" :icon="addressIcon" :attribution="attribution">
                <!-- <l-icon :icon-anchor="[32, 32]" :icon-size="[32, 32]" :icon-url="iconUrl"></l-icon> -->
                <l-popup :content="item.storeName"></l-popup>
              </l-marker>
            </v-marker-cluster>
          </l-map>
        </div>
        <div class="map-list">
          <div class="list-top">
            <div class="list-top-title">{{ $t("附近") }}</div>
          </div>
          <div v-if="dataLists && dataLists.length > 0" v-loading="dataListLoading" style="margin-top: 30px">
            <div v-for="(item, index) in dataLists" :key="index" v-infinite-scroll="loadList" infinite-scroll-disabled="disabled" class="list">
              <div class="list-box">
                <div class="list-image-box">
                  <el-image v-if="item.imgList && item.imgList.length > 0" :src="item.imgList[0]" lazy fit="contain" class="list-image" />
                  <div v-else class="not-image"></div>
                </div>
                <div class="list-content">
                  <div class="item-title">
                    <div class="item-title-content">
                      <p class="list-title-content">
                        <el-link :underline="false" @click="handle(item.id)">{{
                          item.storeName
                        }}</el-link>
                      </p>
                    </div>
                  </div>
                  <div class="list-rate">
                    <!-- <el-rate v-model="item.storeScore" disabled :show-score="false" text-color="#ff9900"  score-template="{value}"> </el-rate> -->
                  </div>
                  <div class="list-info-item">
                    {{ $t("类型") }}：
                    <span v-if="item.storeTypes && item.storeTypes.length > 0">
                      <span v-for="(type, typeIndex) in item.storeTypes" :key="typeIndex">
                        <span v-show="typeIndex > 0">·</span>
                        {{ $t(shopType[type]) }}
                      </span>
                    </span>
                    <span v-else>-</span>
                  </div>
                  <div class="list-info-item">
                    {{ $t("营业时间") }}：
                    <span>
                      <span v-if="
                          item.startBusinessTimeWeek === 0 &&
                            item.endBusinessTimeWeek === 0
                        ">{{ $t("周一") }}~{{ $t("周日") }}</span>
                      <span v-else>{{ $t(weeks[item.startBusinessTimeWeek]) }} ~
                        {{ $t(weeks[item.endBusinessTimeWeek]) }}</span>
                    </span>
                  </div>
                  <div class="list-info-item">
                    <p>
                      <span v-if="item.address" style="line-height: 14px">{{ $t("地址") }}：{{ item.address }}</span>
                      <span v-else>{{ $t("地址") }}：-</span>
                    </p>
                  </div>
                  <div class="list-info-item">
                    <p>
                      <span v-if="item.address" style="line-height: 14px">{{ $t("距离") }}：{{ item.distance
                        }}{{ $t("公里") }}</span>
                      <span v-else>{{ $t("距离") }}：-</span>
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="list-dis-box">
                <div class="cost-discount">
                  <div class="cost-title">{{ $t('大阪乐游劵折扣率') }}：<span v-if="item.osakaSaleRate" class="const-red-content">{{ item.osakaSaleRate }}%</span><span v-else>-</span></div>
                  <div class="cost-title">JR ALLPASS{{ $t('折扣率') }}：<span v-if="item.jrSaleRate" class="const-red-content">{{ item.jrSaleRate }}%</span><span v-else>-</span></div>
                  <div class="cost-title">Tokyo Joyful{{ $t('折扣率') }}：<span v-if="item.tokyoSaleRate" class="const-red-content">{{ item.tokyoSaleRate }}%</span><span v-else>-</span></div>
                </div>
              </div> -->
            </div>
            <p v-if="loading" style="text-align: center;margin-top: 20px;">
              {{ $t("加载中") }}
            </p>
            <p v-if="noMore" style="text-align: center;margin-top: 20px;">
              {{ $t("没有更多了") }}
            </p>
          </div>
          <div v-else v-loading="dataListLoading">
            <div v-if="!dataListLoading && (!dataLists || dataLists.length <= 0)" class="list-not">
              {{ $t("列表暂无数据") }}
            </div>
            <div v-else class="list-not">{{ $t("加载中") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toFixedDigit, lanMap } from "@/utils/util";
import { getNearByStore } from "@/api/store";
import { latLng, icon } from "leaflet";
// LIcon,
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { formatMoney } from "@/utils/money";
import {
  weeks,
  storeService,
  storeShopType,
  getDefaultLon,
  getDefaultLat,
} from "@/utils/public_data";
import L from "leaflet";
import "proj4";
import "proj4leaflet";
import "leaflet.chinatmsproviders";
import i18n from "@/lang/index";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  filters: {
    moneyFilter(val) {
      return formatMoney(val);
    },
    timeFilter(val) {
      return val.substring(0, 5);
    },
  },
  data() {
    return {
      dataList: [],
      dataLists: [],
      longitude: getDefaultLon(),
      latitude: getDefaultLat(),
      zooms: 15,
      zoom: 14,
      mapShow: false,
      center: latLng(this.longitude, this.latitude),
      url: "https://www.google.jp/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}",
      attribution: '&copy; <a href="http://www.google.jp">Google</a>',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      crs: L.CRS.EPSG3857,
      tms: false,
      subdomains: "",
      tileLayerOptions: {
        maxZoom: 18,
        minZoom: 8,
      },
      marker: [],
      iconUrl: require("@/assets/images/address.png"),
      addressIcon: icon({
        iconUrl: require("@/assets/images/address.png"),
        iconSize: [32, 32],
        iconAnchor: [16, 10],
      }),
      mapList: [],
      service: storeService(),
      weeks: weeks(),
      areaList: [],
      moneyList: {},
      shopLogo: require("@/assets/images/shop.png"),
      forward: require("@/assets/images/forward.png"),
      notImg: require("@/assets/images/not-image.png"),
      shopType: storeShopType(),
      num1: 0,
      num2: 6,
      dataListLoading: false,
      sizeAll: 0,
      loading: false,
    };
  },
  created() {
    this.dataListLoading = true;
    lanMap(this, this.lan);
    if (navigator.geolocation) {
      this.showp();
    } else {
      this.getNear();
    }
  },
  computed: {
    noMore() {
      return this.dataLists.length >= this.dataList.length;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    lan() {
      return i18n.locale;
    },
  },
  watch: {
    lan(val, newVal) {
      lanMap(this, newVal);
    },
  },
  methods: {
    loadList() {
      this.loading = true;
      this.num1 = this.num2;
      this.num2 += 3;
      setTimeout(() => {
        const list = this.dataList.slice(this.num1, this.num2);
        this.dataLists = this.dataLists.concat(list);
        this.loading = false;
      }, 3000);
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    showp() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.longitude = Number(toFixedDigit(position.coords.longitude, 6));
          this.latitude = Number(toFixedDigit(position.coords.latitude, 6));
          const latFlag = this.latitude >= 20 && this.latitude <= 40;
          const lngFlag = this.longitude >= 122 && this.longitude <= 154;
          // 超出日本的范围则取东京为初始点
          if (latFlag && lngFlag) {
            // center latLng(35.682084, 139.764887),
            this.center = latLng(
              Number(toFixedDigit(position.coords.latitude, 6)),
              Number(toFixedDigit(position.coords.longitude, 6))
            );
          }
          this.mapShow = true;
          this.getNear();
          console.log(this.longitude, this.latitude);
        },
        (err) => {
          if (err) {
            this.longitude = 135.4959666;
            this.latitude = 34.7025119;
            this.center = latLng(this.latitude, this.longitude);
            this.getNear();
            this.mapShow = true;
          }
        },
        {
          enableHighAccuracy: false,
          timeout: 3000,
          maximumAge: 1000,
        }
      );
    },
    handle(id) {
      this.$router.push({ path: "/detail", query: { id: id } });
    },
    getNear() {
      this.dataListLoading = true;
      getNearByStore({
        longitude: Number(this.longitude),
        latitude: Number(this.latitude),
      }).then((response) => {
        if (response && response.data) {
          console.log(response.data);
          this.dataList = null;
          this.dataLists = null;
          this.dataList = response.data;
          if (this.dataList && this.dataList.length > 0) {
            this.dataList.map((item) => {
              const mapItem = {
                id: item.id,
                latlng: latLng(item.latitude, item.longitude),
                storeName: item.storeName,
              };
              this.mapList.push(mapItem);
              let minPrice = item.breakfast;
              let maxPrice = item.lunch;
              if (minPrice == 0 || minPrice > item.dinner) {
                minPrice = item.dinner;
              }
              if (minPrice == 0 || minPrice > item.lunch) {
                minPrice = item.lunch;
              }
              if (maxPrice == 0 || maxPrice < item.dinner) {
                maxPrice = item.dinner;
              }
              if (maxPrice == 0 || maxPrice < item.breakfast) {
                maxPrice = item.breakfast;
              }
              this.$set(item, "minPrice", minPrice);
              this.$set(item, "maxPrice", maxPrice);
              if (item.storeImg) {
                this.$set(item, "imgList", item.storeImg.split(","));
              }
              if (item.storeType) {
                this.$set(item, "storeTypes", item.storeType.split(","));
              }
            });
          }
        }
        this.dataLists = this.dataList.slice(this.num1, this.num2);
        this.dataListLoading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 277px;
  background: url("../../assets/images/banner_hd.jpg");
  .banner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .banner-title {
      text-align: center;
      color: #fff;
      font-size: 46px;
    }
    .banner-form {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 10px;
      width: 100%;
      height: 140px;
      background: #fff;
      border-radius: 4px;
      z-index: 200;
      .cost-btn {
        margin-top: 50px;
        background: #e7b530;
        color: #fff;
        font-size: 14px;
        width: 120px;
        height: 40px;
      }
    }
  }
  .coverage {
    background: #fff;
    color: #4f2f24;
    padding: 30px;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    .banner-title1 {
      font-size: 42px;
      font-weight: 600;
      padding: 60px 180px;
      border: 2px solid #4f2f24;
    }
    .banner-title1 span {
      font-size: 64px;
      font-weight: 800;
      margin-left: 16px;
    }
  }
}
.map-box {
  display: flex;
  width: 100%;
  height: calc(83vh - 1px);
  .map-warper {
    flex: 1;
    height: calc(83vh - 1px);
    .aside-map {
      width: 100%;
      height: calc(83vh - 1px);
    }
  }
  .map-list {
    min-width: 30%;
    height: calc(83vh - 1px);
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 10px;
  }
}
.list-top {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list-top-title {
    height: 20px;
    // line-height: 20px;
    padding-left: 20px;
    border-left: 4px solid #e7b530;
    font-size: 24px;
    font-weight: 500;
  }
}
.not-image {
  width: 120px;
  width: 120px;
  background: rgb(244, 244, 244);
}
.detail-form {
  padding: 10px;
  box-sizing: border-box;
  ::v-deep .el-form-item {
    width: 100%;
    margin-bottom: 0px;
  }
  ::v-deep .el-form-item__label {
    margin-bottom: 0px;
    padding: 0;
  }
}
.test {
  width: 100%;
  min-height: 900px;
}

.list-top {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list-top-title {
    height: 20px;
    // line-height: 20px;
    padding-left: 20px;
    border-left: 4px solid #e7b530;
    font-size: 18px;
    font-weight: 600;
  }
  .list-top-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .list-key-inp {
      margin-left: 20px;
      width: 200px;
    }
    .list-key-btn {
      margin-left: -2px;
      background: #e7b530;
      color: #000;
      font-weight: 600;
      zoom: -10;
    }
  }
}
.special-box {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  height: 180px;
  background: url("../../assets/images/special.jpg") no-repeat center center;
  z-index: 1;
  border-radius: 3px;
  .content-box {
    padding: 10px;
    width: 80%;
    background: rgba(231, 181, 48, 0.7);
    border-radius: 2px;
    box-sizing: border-box;
    .content-inner-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border: 2px dashed #fff;
      border-radius: 6px;
      .special-title1 {
        margin-top: 10px;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
      .special-title2 {
        margin-top: 10px;
        font-size: 18px;
        color: #fff;
      }
      .enter-btn {
        padding: 7px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        &:hover {
          background: #fff;
          color: #e7b530;
          // border: 4px solid #E7B530;
        }
      }
    }
  }
}
.list {
  padding-top: 16px;
  border-top: 1px solid #dcdfe6;
  width: 100%;
  min-height: 140px;
  &:last-child {
    border-bottom: 1px solid #dcdfe6;
  }
  .list-dis-box {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #fdf6eb;
    .cost-discount {
      margin: 16px 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 12px;
      .cost-title {
        margin-top: 0px;
        font-size: 12px;
        .const-red-content {
          color: #f56c6c;
        }
      }
    }
  }
  .list-box {
    display: flex;
    flex-wrap: nowrap;
    .list-image-box {
      width: 120px;
      .list-image {
        width: 120px;
      }
    }
    .list-content {
      // padding: 0 6px;
      //
      flex: 1;
      // max-width: 240px;
      .list-cost {
        margin-top: 20px;
        display: flex;
        font-weight: 600;
        font-size: 12px;
        .cost-text {
          color: #f56c6c;
        }
      }
      .list-info-item {
        margin-top: 8px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 12px;
      }
      .list-rate {
        margin: 8px;
      }
      .item-title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .item-title-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          .list-title-content {
            width: 100%;
            margin-left: 0px;
            ::v-deep .el-link {
              display: block;
              width: 240px;
              font-size: 16px;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:hover {
                color: #e7b530;
              }
            }
            ::v-deep .el-link--inner {
              display: block;
              width: 100%;
              font-size: 16px;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:hover {
                color: #e7b530;
              }
            }
            margin-left: 10px;
          }
        }
        .shop-log {
          width: 28px;
        }
        .forward-logo {
          width: 28px;
        }
      }
    }
  }
}
.list-page {
  margin-top: 20px;
  text-align: center;
  .el-pagination {
    ::v-deep .btn-prev {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .btn-next {
      background-color: #fff;
      border: 1px solid #dcdfe6;
    }
    ::v-deep .el-pager {
      .number {
        background-color: #fff;
        color: #909399;
        border: 1px solid #dcdfe6;
      }
      li:not(.disabled).active {
        background-color: #e7b530;
      }
    }
  }
}
.list-not {
  width: 100%;
  min-height: 160px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-box {
  width: 100%;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  .search-warpper {
    display: flex;
    .search-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      .btn-icon {
        font-size: 20px;
      }
    }
  }
}
.search-box2 {
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: 30px;
  background: #fff;
  padding: 10px 0;
  z-index: 1000;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  .search-warpper {
    display: flex;
  }
}
.cost-btn {
  background: #e7b530;
  color: #fff;
}
</style>
